<template>
  <div class="content">
    <div class="top">机房环境监控</div>
    <div class="square-top">
      <div>
        <div class="title">监控画面</div>
        <div class="main-Content main-Content-l">
          <iframe width="95%" height="95%" controls :src="viodeUrl" frameborder="0" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
        </div>
      </div>
      <div>
        <div class="title">机房温湿度</div>
        <div class="main-Content">
          <div>
            <div class="tempreature">机房温度数据</div>
            <div class="into-chart">
              <img src="../../assets/tempreature.png">
              <div class="into-chart-m">
                <div>当前温度：{{ alldata.temperature }}℃</div>
                <div>工作状态：{{ alldata.temperatureStatus == 1 ? "异常" : alldata.temperatureStatus == 2 ? "未获取" : "正常"}}</div>
                <div>报警次数：<span class="error">{{ alldata.temperatureCount }}</span>次</div>
              </div>
              <div id="wendu" class="into-chart-r"></div>
            </div>
          </div>
          <div>
            <div class="tempreature">机房湿度数据</div>
            <div class="into-chart">
              <img src="../../assets/water.png">
              <div class="into-chart-m">
                <div>当前湿度：{{ alldata.humidity }}</div>
                <div>工作状态：{{ alldata.humidityStatus == 1 ? "异常" : alldata.humidityStatus == 2 ? "未获取" : "正常" }}</div>
                <div>报警次数：<span class="error">{{ alldata.humidityCount }}</span>次</div>
              </div>
              <div id="shidu" class="into-chart-r"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="square-bottom">
      <div>
        <div class="title">明火监测</div>
        <div class="main-Content main-b-l">
          <div class="danger"></div>
          <div class="fire_l">
            <div class="circle-l">
              <div class="main-circle"></div>
              <div class="title">监测区域</div>
            </div>
            <div class="circle-r">
              <div class="msg">工作状态：{{ alldata.fire == 1 ? "异常" : alldata.fire == 2 ? "未获取" : "正常" }}</div>
              <div class="msg">报警次数：<span class="error">{{ alldata.fireCount }}</span>次</div>
            </div>
          </div>

        </div>
      </div>
      <div>
        <div class="title">烟雾监测</div>
        <div class="main-Content">
          <div class="yanwu">
            <div class="title">烟雾浓度</div>
            <div class="clock" id="yanwu"></div>
            <div class="description">
              <div class="msg">工作状态：{{ alldata.smokeStatus == 1 ? "异常" : alldata.smokeStatus == 2 ? "未获取" : "正常"  }}</div>
              <div class="msg">报警次数：<span class="error">{{ alldata.smokeCount }}</span>次</div>
            </div>
          </div>

        </div>
      </div>
      <div>
        <div class="title">水浸监测</div>
        <div class="main-Content">
          <div class="yanwu">
            <div class="flood" id="flood"></div>
            <div class="description">
              <div class="msg">工作状态：{{ alldata.water == 1 ? "异常" : alldata.water == 2 ? "未获取" : "正常" }}</div>
              <div class="msg">报警次数：<span class="error">{{ alldata.waterCount }}</span>次</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from "vue";
import * as echarts from "echarts";
import * as liquidfill from "echarts-liquidfill";
import { getSensor } from "@/api/motorroom/centre";
import { gettimeInterval, getvideoData } from "@/api/screen/machineMonitor";
import { getNowTime } from "../../util/date";

export default {
  name: "machineMonitor",
  setup() {
    let option = reactive({
      viodeUrl: "",
      timer: null,
      wdData: [],
      sdData: [],
      alldata: {}
    });
    onBeforeUnmount(() => {
      clearInterval(option.timer);
      option.timer = null;
    });
    onMounted(() => {
      document.body.style.setProperty('--main-Content', 479 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--main-Content-height', 427 / 1080 * document.body.clientHeight + 'px');

      wdChart();
      sdChart();
      yanwuChart();
      floodChart();
      gettimeInterval().then(res => {
        option.timer = setInterval(() => {
          getSensor().then(res => {
            option.alldata = res.data.data;
            wdChart(res.data.data.temperature);
            sdChart(res.data.data.humidity);
            yanwuChart(res.data.data.smoke);
            floodChart(res.data.data.water);
          });
        }, res.data.data.timingOne);
      });
      getvideoData().then(res => {
        if (res.data.success) {
          option.viodeUrl = res.data.data[0].serial + "autoplay=1";
        }
      });
    });

    function wdChart(data = []) {
      let chartDom = document.getElementById("wendu");
      let myChart = echarts.init(chartDom);
      if (option.wdData.length > 50) option.wdData.shift();
      let time = getNowTime();
      option.wdData.push({
        name: time,
        value: [time, data]
      });
      let chartoption = {
        grid: {
          top: "10%",
          right: "10%",
          bottom: "15%",
          left: "10%"
        },
        tooltip: {
          trigger: "axis",
          formatter: function(params) {
            let param = params[0];
            return `时间${param.value[0]}\n 温度${param.value[1]}℃`;
          },
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          show: true,
          type: "category",
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          }
        },
        yAxis: {
          show: true,
          type: "value",
          max: "50",
          min: "-20",
          interval: 10,
          boundaryGap: [0, "100%"],
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value}℃"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#082e2a",
              type: "solid"
            }
          }
        },
        series: [
          {
            type: "line",
            showSymbol: false,
            data: option.wdData,
            symbolSize: 8,
            normal: {
              show: true
            },
            lineStyle: {
              color: "#20f120"
            },
            itemStyle: {
              color: "#e1d00b",
              borderType: "solid"
            }
          }
        ]
      };
      chartoption && myChart.setOption(chartoption);
    }

    function sdChart(data = []) {
      let chartDom = document.getElementById("shidu");
      let myChart = echarts.init(chartDom);
      if (option.sdData.length > 50) option.sdData.shift();
      option.sdData.push({
        name: getNowTime(),
        value: [getNowTime(), data]
      });
      let chartoption = {
        grid: {
          top: "10%",
          right: "10%",
          bottom: "15%",
          left: "10%"
        },
        tooltip: {
          trigger: "axis",
          formatter: function(params) {
            let param = params[0];
            return `时间${param.value[0]}\n 湿度${param.value[1]}%rh`;
          },
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          show: true,
          type: "category",
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          }
        },
        yAxis: {
          show: true,
          max: 100,
          min: 0,
          interval: 20,
          type: "value",
          boundaryGap: [0, "100%"],
          axisLabel: {
            show: true
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#082e2a",
              type: "solid"
            }
          }
        },
        series: [
          {
            type: "line",
            showSymbol: false,
            data: option.sdData,
            symbolSize: 8,
            normal: {
              show: true
            },
            lineStyle: {
              color: "#20f120"
            },
            itemStyle: {
              color: "#e1d00b",
              borderType: "solid"
            }
          }
        ]
      };
      chartoption && myChart.setOption(chartoption);
    }

    function yanwuChart(data = 0) {
      let chartDom = document.getElementById("yanwu");
      let myChart = echarts.init(chartDom);
      let chartoption = {
        series: [
          {
            type: "gauge",
            radius: "90%",
            center: ["55%", "45%"],
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.3, "#fd666d"],
                  [0.7, "#00DAAE"],
                  [1, "#F4D820"]
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: "auto"
              }
            },
            axisTick: {
              distance: 0,
              length: 8,
              lineStyle: {
                color: "#32B89C",
                width: 1
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              color: "auto",
              distance: 0,
              fontSize: 10
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}%",
              color: "auto"
            },
            data: [
              {
                value: data,
                label: {
                  textStyle: {
                    fontSize: 12
                  }
                }
              }
            ]
          }
        ]
      };
      chartoption && myChart.setOption(chartoption);
    }

    function floodChart(data = 0) {
      let chartDom = document.getElementById("flood");
      let myChart = echarts.init(chartDom);
      let chartoption = {
        series: [
          {
            type: "liquidFill",
            data: [data],
            color: ["#00DAAE"],
            radius: "90%",
            outline: {
              show: false
            },
            itemStyle: {
              color: "#00DAAE",
              opacity: 0.6
            },
            backgroundStyle: {
              borderColor: "#00DAAE",
              borderWidth: 3,
              color: "#052323"
            },
            label: {
              normal: {
                formatter() {
                  if (data == 1) return "已浸水";
                  if (data == 2) return "未获取";
                  else return "无浸水";
                },
                textStyle: {
                  color: "#00F6C5",
                  fontSize: 20
                }
              }
            }
          }
        ]
      };
      chartoption && myChart.setOption(chartoption);
    }

    return {
      ...toRefs(option)
    };
  }
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  overflow: hidden;
  padding: 0 10px;

  .top {
    width: 100%;
    height: 66px;
    background: url("../../assets/monitorhead.png") center no-repeat;
    font-size: 38px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }

  .error {
    color: red;
  }

  .msg {
    text-align: center;
    line-height: 45px;
    font-size: 21px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #00FFCC;
    width: 190px;
    height: 45px;
    background: url("../../assets/chart-m.png") left top no-repeat;
    background-size: 100% 100%;
  }

  .square-top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;

    div {
      .title {
        text-align: left;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #00DAAE;
      }

      .main-Content {
        height: var(--main-Content);
        width: 100%;
        background: url("../../assets/monitorBg.png") center top no-repeat;
        background-size: 100% 100%;
        padding: 10px;

        &.main-Content-l {
          display: flex;
          justify-content: center;
          align-items: center;

          .video {
            width: calc(100% - 30px);
            height: calc(100% - 20px);
          }
        }

        > div {
          text-align: left;
          padding: 0px 10px;

          .tempreature {
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 300;
            color: #32B89C;
            background: url("../../assets/bgline.png") left bottom no-repeat;
          }

          .into-chart {
            display: flex;
            justify-content: space-around;
            align-items: center;

            &:first-child {
              margin-bottom: 20px;
            }

            > img {
              width: 138px;
              height: 138px;
            }

            .into-chart-m {
              > div {
                text-align: center;
                line-height: 45px;
                font-size: 21px;
                font-family: Source Han Sans SC;
                font-weight: 300;
                color: #00FFCC;
                width: 190px;
                height: 45px;
                background: url("../../assets/chart-m.png") left top no-repeat;
                background-size: 100% 100%;
              }
            }

            .into-chart-r {
              width: 500px;
              height: 190px;
            }
          }

        }
      }

    }

  }

  .square-bottom {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;

    div > {
      .title {
        text-align: left;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #00DAAE;
      }

      .main-Content {
        height: var(--main-Content-height);
        width: 100%;
        background: url("../../assets/monitorBg.png") center top no-repeat;
        background-size: 100% 100%;
        padding: 10px;

        &.main-b-l {
          position: relative;
        }


        .danger {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 90px;
          height: 93px;
          background: url("../../assets/alarm.png") left top no-repeat;
          background-size: 100% 100%;
        }

        .fire_l {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;

          .circle-l {
            margin-right: 20px;

            //.main-circle {
            //  width: 256px;
            //  height: 251px;
            //  background-size: 100% 100%;
            //  margin-bottom: 10px;
            //}

            .main-circle {
              background: -webkit-radial-gradient(center, rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), -webkit-repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, #20ff4d 18.6%, rgba(32, 255, 77, 0) 18.9%), -webkit-linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 50%, #20ff4d 50%, rgba(32, 255, 77, 0) 50.2%), -webkit-linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 50%, #20ff4d 50%, rgba(32, 255, 77, 0) 50.2%);
              background: radial-gradient(center, rgba(32, 255, 77, 0.3) 0%, rgba(32, 255, 77, 0) 75%), repeating-radial-gradient(rgba(32, 255, 77, 0) 5.8%, rgba(32, 255, 77, 0) 18%, #20ff4d 18.6%, rgba(32, 255, 77, 0) 18.9%), linear-gradient(90deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 50%, #20ff4d 50%, rgba(32, 255, 77, 0) 50.2%), linear-gradient(0deg, rgba(32, 255, 77, 0) 49.5%, #20ff4d 50%, #20ff4d 50%, rgba(32, 255, 77, 0) 50.2%);
              width: 256px;
              height: 251px;
              margin-bottom: 10px;
              max-height: 256px;
              max-width: 251px;
              position: relative;
              left: 50%;
              top: 50%;
              transform: translate(-50%, 0%);
              border-radius: 50%;
              border: 1px solid #20ff4d;
              overflow: hidden;
            }

            .main-circle:before {
              content: ' ';
              display: block;
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              animation: blips 5s infinite;
              animation-timing-function: linear;
              animation-delay: 1.4s;
            }

            .main-circle:after {
              content: ' ';
              display: block;
              background-image: linear-gradient(44deg, rgba(0, 255, 51, 0) 50%, #00ff33 100%);
              width: 50%;
              height: 50%;
              position: absolute;
              top: 0;
              left: 0;
              animation: radar-beam 5s infinite;
              animation-timing-function: linear;
              transform-origin: bottom right;
              border-radius: 100% 0 0 0;
            }

            @keyframes radar-beam {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
            @keyframes blips {
              14% {
                background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
              }
              14.0002% {
                background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
              }
              25% {
                background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
              }
              26% {
                background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
                opacity: 1;
              }
              100% {
                background: radial-gradient(2vmin circle at 75% 70%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 63% 72%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%), radial-gradient(2vmin circle at 56% 86%, #ffffff 10%, #20ff4d 30%, rgba(255, 255, 255, 0) 100%);
                opacity: 0;
              }
            }

            .title {
              text-align: center;
            }
          }


        }

        .yanwu {
          display: flex;
          padding: 10px;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;

          .clock, .flood {
            width: 252px;
            height: 239px;
            //background-size: 100% 100%;
          }

          .flood {
            margin-top: 50px;
          }

          .description {
            display: flex;
            justify-content: space-evenly;
          }
        }
      }
    }
  }
}

</style>
import axios from "@/api/axios"; // 引用axios
//获取cpu使用情况
export const getCpu = (params = {}) => {
  return axios({
    url: "/api/big/screen/getCpu",
    method: "post",
    params: params
  });
};
//获取内存使用情况
export const getMemory = (params = {}) => {
  return axios({
    url: "/api/big/screen/getMemory",
    method: "post",
    params: params
  });
};//获取硬盘使用情况
export const getDisk = (params = {}) => {
  return axios({
    url: "/api/big/screen/getDisk",
    method: "post",
    params: params
  });
};

export const getNet = () => {
  return axios({
    url: "/api/big/screen/getNet",
    method: "post",
  });
};

export const getProcList = () => {
  return axios({
    url: "/api/big/screen/getProcList",
    method: "post",
  });
};


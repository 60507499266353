import axios from "@/api/axios"; // 引用axios

export const bcastCount = (params) => {
    return axios({
        url: "/api/effect/bcastCount",
        method: "post",
        params: params,
    });
};

export const dataCount = (params) => {
    return axios({
        url: "/api/effect/source/count",
        method: "post",
        params: params,
    });
};

export const dataNameCount = (params) => {
    return axios({
        url: "/api/effect/bcast/getByNameCount",
        method: "post",
        params: params,
    });
};

export const dataNumber = (params) => {
    return axios({
        url: "/api/effect/resource/numberCount",
        method: "post",
        params: params,
    });
};


export const bcastList = (params) => {
    return axios({
        url: "/api/effect/bcast/bcastList",
        method: "post",
        params: params,
    });
};

export const datdBycount = (params) => {
    return axios({
        url: "/api/effect/resource/count",
        method: "post",
        params: params,
    });
};

<template>
  <div class="content">
    <div class="top">机房系统监控</div>
    <div class="square-top">
      <div class="main-Content">
        <div class="title">cpu使用情况</div>
        <div class="danger"></div>
        <div class="echart" id="cpu"></div>
        <div>
          <span class="textmsg">工作状态：{{ cpuObj.status == 1 ? "异常" : "正常" }}</span>
          <span class="textmsg">报警次数：<span>{{ cpuObj.count }}</span>次</span>
        </div>
      </div>
      <div class="main-Content">
        <div class="title">内存使用情况</div>
        <div class="echart" id="memory"></div>
        <div>
          <span class="textmsg">工作状态：{{ memoryObj.status == 1 ? "异常" : "正常" }}</span>
          <span class="textmsg">报警次数：<span>{{ memoryObj.count }}</span>次</span>
        </div>
      </div>
      <div class="main-Content">
        <div class="title">进程情况</div>
        <div class="course-box">
          <table style="width: 100%;border: 1px solid #0B4439;background: #12322E;color:#00FFCC;font-size: 18px;border-collapse: collapse;">
            <thead class="thead">
              <tr>
                <th>名称</th>
                <th>状态</th>
                <th>CPU</th>
                <th>内存</th>
              </tr>
            </thead>
            <tbody class="tbody">
              <tr v-for="(item,index) in procList" :key="index">
                <td>{{item.processName}}</td>
                <td>{{item.state}}</td>
                <td>{{(item.cpu * 100).toFixed(2)}}%</td>
                <td>{{item.memory}}M</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="square-bottom">
      <div class="main-Content">
        <div class="title">硬盘分区使用情况</div>
        <div class="danger"></div>
        <div class="harddiskArea">
          <div id="harddisk"></div>
          <div class="disk">
<!--              <span class="textmsg">C盘工作状态：{{ diskData[0]?.status == 1 ? "异常" : "正常" }}</span>-->
<!--              <span class="textmsg">D盘工作状态：{{ diskData[1]?.status == 1 ? "异常" : "正常" }}</span>-->
<!--              <span class="textmsg">E盘工作状态：{{ diskData[2]?.status == 1 ? "异常" : "正常" }}</span>-->
<!--              <span class="textmsg">F盘工作状态：{{ diskData[3]?.status == 1 ? "异常" : "正常" }}</span>-->
<!--              <span class="textmsg">G盘工作状态：{{ diskData[4]?.status == 1 ? "异常" : "正常" }}</span>-->
<!--              <span class="textmsg">H盘工作状态：{{ diskData[5]?.status == 1 ? "异常" : "正常" }}</span>-->
            <span class="textmsg" v-for="(item, index) in diskData" :key="index">{{ item.name }}盘工作状态：{{ item?.status == 1 ? "异常" : "正常" }}</span>
          </div>
        </div>
      </div>

      <div class="main-Content">
        <div class="title">以太网详情</div>
        <div class="chartMain" :class="contrast2.length > 0 && 'active'">
          <div class="chartMain-list">
            <span>{{contrast[0]?.description}}</span>
            <div class="chart" id="chart"></div>
            <section class="chart-info">
              <div class="chart-info-t">
                <div class="chart-info-t-lr">
                  <span>{{ txBytes > 1024 ? `${(txBytes / 1024).toFixed(2)} M` : txBytes > 1024*1024 ? `${(txBytes / (1024*1024)).toFixed(2)} G` : `${txBytes} kbps` }}</span>
                  <span>发送</span>
                </div>
                <div class="chart-info-t-lr bo">
                  <span>{{rxBytes > 1024 ? `${(rxBytes / 1024).toFixed(2)} M` : rxBytes > 1024*1024 ? `${(rxBytes / (1024*1024).toFixed(2))} G` : `${rxBytes} kbps`}}</span>
                  <span>接收</span>
                </div>
              </div>
              <div class="chart-info-b">
                <span>IPv4 地址: {{contrast[0]?.ip}}</span>
                <span>IPv6 地址: fe80::6de8:a3bb:b280:9ac7%10</span>
              </div>
            </section>
          </div>

          <div class="chartMain-list" v-if="contrast2.length > 0">
            <span>{{contrast2[0]?.description}}</span>
            <div class="chart" id="chart2"></div>
            <section class="chart-info">
              <div class="chart-info-t">
                <div class="chart-info-t-lr">
                  <span>{{ txBytes2 > 1024 ? `${txBytes2 / 1024} M` : txBytes2 > 1024*1024 ? `${txBytes2 / (1024*1024)} G` : `${txBytes2} kbps` }}</span>
                  <span>发送</span>
                </div>
                <div class="chart-info-t-lr bo">
                  <span>{{rxBytes2 > 1024 ? `${rxBytes2 / 1024} M` : rxBytes2 > 1024*1024 ? `${rxBytes2 / (1024*1024)} G` : `${rxBytes2} kbps`}}</span>
                  <span>接收</span>
                </div>
              </div>
              <div class="chart-info-b">
                <span>IPv4 地址: {{contrast2[0]?.ip}} IPv6</span>
                <span>地址: fe80::6de8:a3bb:b280:9ac7%10</span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from "vue";
import * as echarts from "echarts";
import { getCpu, getMemory, getDisk, getNet, getProcList } from "@/api/screen/systemMonitor";
import { gettimeInterval } from "@/api/screen/machineMonitor";
import { getNowTime } from "../../util/date";


export default {
  name: "systemMonitor",
  setup() {
    const option = reactive({
      procList:[],
      rxBytes2:0,
      txBytes2: 0,
      rxBytes:0,
      txBytes:0,
      txData2:[],
      rxData2:[], //给tx最终数据
      txData:[],
      rxData:[], //给tx最终数据
      contrast2:[],
      contrast:[], //前后数据对比
      cpuObj: {},
      memoryObj: {},
      cpuData: [],
      memoryData: [],
      diskData: [],
      cpuEchart: null,
      myEchart: null,
      diskEchart: null
    });

    function cpuChart(data = 0, _basic = 0) {
      // let dom = document.getElementById("cpu");
      // let myEchart = echarts.init(dom);
      if (option.cpuData.length > 30) option.cpuData.shift();
      let time = getNowTime();
      option.cpuData.push({
        name: time,
        value: [time, data]
      });
      let chartOption = {
        grid: {
          top: "10%",
          right: "10%",
          bottom: "15%",
          left: "20%"
        },
        tooltip: {
          trigger: "axis",
          // formatter: function(params) {
          //   let param = params[0];
          //   return `时间${param.value[0]}\n ${param.value[1]}%`;
          // },
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          show: true,
          type: "category",
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          }
        },
        yAxis: {
          show: true,
          type: "value",
          max: "100",
          min: "0",
          interval: 20,
          boundaryGap: [0, "100%"],
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value}%"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#082e2a",
              type: "solid"
            }
          }
        },
        series: [
          {
            type: "line",
            showSymbol: false,
            data: option.cpuData,
            symbolSize: 8,
            normal: {
              show: true
            },
            lineStyle: {
              color: "#F4DF0A"
            },
            itemStyle: {
              color: "#F4DF0A",
              borderType: "solid"
            },
            // normal: {
            //   show: true
            // },
            // itemStyle: {
            //   normal: {
            //     lineStyle: {
            //       color: "#F4DF0A",
            //       borderType: "solid"
            //     }
            //   }
            // },
            markLine: {
              label: {
                position: "end",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                formatter: "告警线",
                color: "rgba(238, 99, 99)"
              },
              data: [{
                silent: false,             //鼠标悬停事件  true没有，false有
                lineStyle: {               //警戒线的样式  ，虚实  颜色
                  type: "dashed",
                  color: "rgba(238, 99, 99)"
                },
                yAxis: _basic
              }]
            }

          }
        ]
      };
      chartOption && option.cpuEchart.setOption(chartOption);
    }

    function memoryChart(data = 0, _basic = 0) {
      // let dom = document.getElementById("memory");
      // let myEchart = echarts.init(dom);
      if (option.memoryData.length > 30) option.memoryData.shift();
      let time = getNowTime();
      option.memoryData.push({
        name: time,
        value: [time, data]
      });
      let chartOption = {
        grid: {
          top: "10%",
          right: "10%",
          bottom: "15%",
          left: "20%"
        },
        tooltip: {
          trigger: "axis",
          formatter: function(params) {
            let param = params[0];
            return `时间${param.value[0]}  内存:${param.value[1]}`;
          },
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          show: true,
          type: "category",
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          }
        },
        yAxis: {
          show: true,
          type: "value",
          max: "100",
          min: "0",
          interval: 20,
          boundaryGap: [0, "100%"],
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value}%"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#082e2a",
              type: "solid"
            }
          }
        },
        series: [
          {
            type: "line",
            showSymbol: false,
            data: option.memoryData,
            symbolSize: 8,
            normal: {
              show: true
            },
            lineStyle: {
              color: "#F4DF0A"
            },
            itemStyle: {
              color: "#F4DF0A",
              borderType: "solid"
            },
            // normal: {
            //   show: true
            // },
            // itemStyle: {
            //   normal: {
            //     lineStyle: {
            //       color: "#F4DF0A",
            //       borderType: "solid"
            //     }
            //   }
            // },
            markLine: {
              label: {
                position: "end",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
                formatter: "告警线",
                color: "rgba(238, 99, 99)"
              },
              data: [{
                silent: false,             //鼠标悬停事件  true没有，false有
                lineStyle: {               //警戒线的样式  ，虚实  颜色
                  type: "dashed",
                  color: "rgba(238, 99, 99)"
                },
                yAxis: _basic
              }]
            }

          }
        ]
      };
      chartOption && option.myEchart.setOption(chartOption);
    }

    function diskChart(data = []) {
      let chartOption = {
        grid: {
          top: "10%",
          right: "10%",
          left: "5%",
          height: "90%",
          containLabel: true
        },
        xAxis: {
          show: true,
          type: "category",
          splitLine: {
            show: false
          },
          data: data.map(item=>`${item.name}盘`),
          axisLine: {
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          }
        },
        yAxis: {
          show: true,
          max: 100,
          min: 0,
          interval: 20,
          type: "value",
          boundaryGap: [0, "100%"],
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: "{value}%"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4b9c8c",
              width: 1
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#082e2a",
              type: "solid"
            }
          }
        },
        series: [
          {
            data: data.map(item=>item.value),
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "#02AC91",
              opacity: 0.2
            },

            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: "#00E5C0" },                //柱图渐变色
                    { offset: 0.7, color: "#0C3834" }                   //柱图渐变色
                  ]
                )
              }
            },
            label: {
              normal: {
                show: true,
                position: "top",
                formatter(data) {
                  return data.value + "%";
                },
                textStyle: {
                  color: "#00F6C5",
                  fontSize: 12
                }
              }
            }
            // markLine: {
            //   symbol: "none",
            //   label: {
            //     position: "middle",         //将警示值放在哪个位置，三个值“start”,"middle","end"  开始  中点 结束
            //     formatter: "告警线",
            //     color: "rgba(238, 99, 99)"
            //   },
            //   data: [{
            //     coord: [10,90],
            //     silent: false,             //鼠标悬停事件  true没有，false有
            //     lineStyle: {               //警戒线的样式  ，虚实  颜色
            //       type: "dashed",
            //       width: 5,
            //       color: "rgba(238, 99, 99)"
            //     },
            //     yAxis:90,
            //   },{
            //     coord: [10,90],
            //     silent: false,             //鼠标悬停事件  true没有，false有
            //     lineStyle: {               //警戒线的样式  ，虚实  颜色
            //       type: "dashed",
            //       width: 5,
            //       color: "rgba(238, 99, 99)"
            //     },
            //     yAxis:60,
            //   }]
            // }
          },
        ]
      };
      chartOption && option.diskEchart.setOption(chartOption);
    }

    function netChart(id){
      var chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom);
      let op = {
        animation: false,
        grid: {
          top: "0%",
          right: "0%",
          bottom: "0%",
          left: "0%"
        },
        tooltip: {
          trigger: 'axis',
          formatter:(params)=>{
            let tip = "";
            if(params != null && params.length > 0) {
              for(let i =0; i < params.length; i++) {
                let name = params[i].seriesName;
                let value = params[i].value;
                if(value > 1024){
                  value = (value / 1024).toFixed(2) + 'M';
                }else if(value > 1024*1024){
                  value = (value / (1024*1024)).toFixed(2) + 'G';
                }else {
                  value = value + 'kbps'
                }

                tip += name + ":" + value + "</br>"
              }
            }
            return tip;
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          inverse:true,
          type: 'category',
          boundaryGap: false,
          data: ['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',''],
          splitLine: {
            lineStyle: {
              color: "#08312d"
            },
            show:true,
          }
        },
        yAxis: {
          // max: 10,
          type: 'value',
          axisLine:{
            lineStyle: {
              show: false
            }
          },
          axisTick:{
            show: false
          },
          splitLine: {
            lineStyle: {
              color: "#08312d"
            }
          }
        },
        series: [
          {
            name: '接收',
            data: id == 'chart' ? option.rxData : option.rxData2,
            type: 'line',
            smooth: false,
            areaStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 1,
                  x2: 0,
                  y2: 0,
                  colorStops: [{
                    offset: 0.5,
                    color: 'rgb(255,224,4,.2)' // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: 'rgb(255,224,4,.5)' // 100% 处的颜色
                  }],
                  globalCoord: false // 缺省为 false
                }
              },
            },
            lineStyle: {
              color: "#F4DF0A"
            },
            symbol: "none"
          },
          {
            name: '发送',
            type:'line',
            stack: '总量',
            smooth: false,
            symbol: "none",
            itemStyle:{
              normal:{
                lineStyle:{
                  type:'dotted'
                }
              }
            },
            data:id == 'chart' ? option.txData : option.txData2
          },
        ]
      }
      op && myChart.setOption(op);
    }

    onBeforeUnmount(() => {
      clearInterval(option.timer);
      option.timer = null;
      clearInterval(option.timer2);
      option.timer2 = null;
      clearInterval(option.timer3);
      option.timer3 = null;
    })

    onMounted(() => {
      document.body.style.setProperty('--main-Content', 460 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--echart-width', 510 / 1920 * document.body.clientWidth + 'px');
      document.body.style.setProperty('--echart-height', 260 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--textmsg-width', 180 / 1920 * document.body.clientWidth + 'px');
      document.body.style.setProperty('--textmsg-height', 45 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--harddiskArea-columns', `${520 / 1920 * document.body.clientWidth}px ${250 / 1920 * document.body.clientWidth}px`);
      document.body.style.setProperty('--harddiskArea-height', 340 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--textmsg-padding', 8 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--chartMain-height', 350 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--chart-height', 168 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--chart-info-t-lr', 60 / 1080 * document.body.clientHeight + 'px');
      document.body.style.setProperty('--chartMain-width', 90 / 1920 * document.body.clientHeight + 'px');
      option.timer2 = setInterval(()=>{
        getNet().then(res=>{
          option.contrast.push(res.data.data.slice(0,2)[0]);
          if(res.data.data.slice(0,2).length > 1) option.contrast2.push(res.data.data.slice(0,2)[1]);
          if(option.contrast.length > 1){
            option.rxBytes = (option.contrast[option.contrast.length - 1].rxBytes - option.contrast[option.contrast.length - 2].rxBytes) / 1000;
            option.txBytes = (option.contrast[option.contrast.length - 1].txBytes - option.contrast[option.contrast.length - 2].txBytes) / 1000;
            if(option.rxData.length >= 60) option.rxData.shift();
            if(option.txData.length >= 60) option.txData.shift();
            option.rxData.push(option.rxBytes.toFixed(0));
            option.txData.push(option.txBytes.toFixed(0));
            netChart('chart');
          };

          if(option.contrast2.length > 1){
            option.rxBytes2 = (option.contrast2[option.contrast2.length - 1].rxBytes - option.contrast2[option.contrast2.length - 2].rxBytes) / 1000;
            option.txBytes2 = (option.contrast2[option.contrast2.length - 1].txBytes - option.contrast2[option.contrast2.length - 2].txBytes) / 1000;
            if(option.rxData2.length >= 60) option.rxData2.shift();
            if(option.txData2.length >= 60) option.txData2.shift();
            option.rxData2.push(option.rxBytes2.toFixed(0));
            option.txData2.push(option.txBytes2.toFixed(0));
            netChart('chart2');
          };
        })
      },1000)

      let arr = [];
      option.timer3 = setInterval(()=>{
        getProcList().then(res=>{
          option.procList = arr.map((item,index)=>{
            return{
              ...item,
              cpu:item.cpuTotal ? ((item.cpuTotal || 0) - (res.data.data[index].cpuTotal || 0)) / (item.cpuTime - res.data.data[index].cpuTime) / item.cpuCount : 0
            }
          });
          arr = res.data.data;
        })
      },2000)


      let dom = document.getElementById("cpu");
      option.cpuEchart = echarts.init(dom);
      let dom1 = document.getElementById("memory");
      option.myEchart = echarts.init(dom1);
      let dom2 = document.getElementById("harddisk");
      option.diskEchart = echarts.init(dom2);
      cpuChart();
      memoryChart();
      diskChart();
      gettimeInterval().then(res => {
        option.timer = setInterval(() => {
          getCpu().then(res => {
            if (res.data.success) {
              option.cpuObj = res.data.data;
              cpuChart(res.data.data.value, res.data.data.basic);
            }
          });
          getMemory().then(res => {
            if (res.data.success) {
              option.memoryObj = res.data.data;
              memoryChart(res.data.data.value,res.data.data.basic );
            }
          });
          getDisk().then(res => {
            if (res.data.success) {
              option.diskData = res.data.data;
              console.log(option.diskData)
              // let arr = [];
              // data.map(item => {
              //   arr.push(item.value);
              // });
              diskChart(res.data.data);
            }
          });
        }, res.data.data.timingTwo);
      });
    });
    return {
      ...toRefs(option)
    };
  }
};
</script>

<style scoped lang="scss">
.content {
  width: 100%;
  overflow: hidden;
  padding: 0 10px;
  box-sizing: border-box;

  .top {
    width: 100%;
    height: 66px;
    background: url("../../assets/monitorhead.png") center no-repeat;
    font-size: 38px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }

  .square-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    margin: 30px 0;
    .main-Content {
      height: var(--main-Content);/*no*/
      background: url("../../assets/monitorBg.png") center top no-repeat;
      background-size: 100% 100%;
      padding: 10px 10px 10px 40px;
      position: relative;
      overflow: auto;
      &::-webkit-scrollbar {display:none}
      .title {
        text-align: left;
        font-size: 24px;
        font-family: Source Han Sans SC;
        font-weight: 300;
        color: #32B89C;
        background: url("../../assets/bgline.png") left bottom no-repeat;
      }

      .danger {
        position: absolute;
        right: 30px;
        top: 10px;
        width: 90px;
        height: 93px;
        background: url("../../assets/alarm.png") left top no-repeat;
        background-size: 100% 100%;
      }

      .echart {
        width: var(--echart-width); /*no*/
        height: var(--echart-height); /*no*/
      }

      .textmsg {
        line-height: var(--textmsg-height); /*no*/
        display: inline-block;
        text-align: center;
        font-size: 21px;
        font-family: Source Han Sans SC;
        font-weight: 300;
        color: #00FFCC;
        width: var(--textmsg-width);/*no*/
        height: var(--textmsg-height); /*no*/
        background: url("../../assets/chart-m.png") center no-repeat;
        background-size: 100% 100%;
      }

      .course-box{
        width: 100%;
        .tbody td{
          background: #09211e;
          border: 1px solid #0B4439;
        }
        .thead th{
          border: 1px solid #0B4439;
        }
      }
    }
  }

  .square-bottom {
    //width: 1544px;
    //height: 434px;
    //background: url("../../assets/monitorBg.png") left top no-repeat;
    //background-size: 100% 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .main-Content {
      position: relative;
      height: var(--main-Content); /*no*/
      background: url("../../assets/monitorBg.png") center top no-repeat;
      background-size: 100% 100%;
      padding: 10px 10px 10px 40px;

      .title {
        width: 220px;
        text-align: left;
        font-size: 24px;
        font-family: Source Han Sans SC;
        font-weight: 300;
        color: #32B89C;
        background: url("../../assets/bgline.png") left bottom no-repeat;
        background-size: 100% 100%;
      }

      .danger {
        position: absolute;
        right: 30px;
        top: 10px;
        width: 90px;
        height: 93px;
        background: url("../../assets/alarm.png") left top no-repeat;
        background-size: 100% 100%;
      }

      .harddiskArea {
        width: 100%;
        display: grid;
        height: var(--harddiskArea-height);/*no*/
        grid-template-columns:var(--harddiskArea-columns);
        grid-column-gap: 20px;
        .disk {
          display: flex;
          flex-direction: column;
          .textmsg {
            padding: 0 20px;
            line-height: var(--textmsg-height); /*no*/
            display: inline-block;
            text-align: center;
            font-size: 21px;
            font-family: Source Han Sans SC;
            font-weight: 300;
            color: #00FFCC;
            //width: 180px;
            height: var(--textmsg-height); /*no*/
            margin: var(--textmsg-padding) 0 /*no*/;
            background: url("../../assets/chart-m.png") left top no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .chartMain{
        margin: 20px 60px 0 30px;
        width: calc(100% - var(--chartMain-width)); /*no*/
        height: var(--chartMain-height); /*no*/
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        &.active{
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 70px;
        }
        .chartMain-list{
          width: 100%;
          text-align: left;
          >span{
            font-size: 16px;
            font-weight: 400;
            color: #02D9AE;
          }
        }
        .chart{
          width: 100%;
          height: var(--chart-height);/*no*/
          margin-top: 20px;
        }
        .chart-info{
          width: 100%;
          margin-top: 10px;
          .chart-info-t{
            display: flex;
            align-items: center;
            .chart-info-t-lr{
              display: flex;
              flex-direction: column;
              height: var(--chart-info-t-lr); /*no*/
              border-left: 2px solid #F4DF0A;
              padding-left: 5px;
              margin-right: 36px;
              &.bo{
                border-left: 2px dashed #F4DF0A;
              }
              span{
                &:nth-child(1){
                  font-size: 24px;
                  font-weight: 400;
                  color: #F4DF0A;
                }
                &:nth-child(2){
                  font-size: 16px;
                  font-weight: 300;
                  color: #59B7A4;
                }
              }
            }
          }
          .chart-info-b{
            margin-top: 20px;
            font-size: 16px;
            font-weight: 400;
            color: #498075;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}
</style>
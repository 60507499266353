<template>
  <div class="main">
    <div class="chart-top">
      <div id="bcType">
        <div class="title">广播类型统计</div>
        <div class="chart" id="gb"></div>
        <div class="download">
          <div class="year">{{ year }}年</div>
<!--          <img src="../../assets/download.png" @click="getimg(0)">-->
          <div @click="changechartType(0)">
            <el-icon>
              <d-caret />
            </el-icon>
            切换
          </div>
        </div>

      </div>
      <div id="levelBox">
        <div class="title">级别统计</div>
        <div class="chart flex" v-show="levelShow==1">
          <div class="chart-line" v-for="(item,index) in bcasCount" :key="index">
            <div class="chart-line-l"
                 :class="item.name == '红(特别重大)' ? 'one' : item.name == '橙(重大)' ? 'two' : item.name == '黄(较大)' ? 'three' : item.name == '蓝(一般)' ? 'four' : 'five'">
              {{ item.name }}
            </div>
            <div class="chart-line-r">
              <div :style="{width : `${(item.num / nums)*100}%`}" class="chart-line-r-in"
                   :class="item.name == '红(特别重大)' ? 'one' : item.name == '橙(重大)' ? 'two' : item.name == '黄(较大)' ? 'three' : item.name == '蓝(一般)' ? 'four' : 'five'"></div>
              <span class="chart-line-r-num">{{ item.num }}次</span>
            </div>
          </div>
        </div>
        <div id="levelcharts" style="width: 586px;height: 392px;" class="chart flex" v-show="levelShow==2"></div>

        <div id="levelpie" style="width: 586px;height: 392px;" class="chart flex" v-show="levelShow==3"></div>

        <div class="download">
          <div class="year">{{ year }}年</div>
<!--          <img src="../../assets/download.png" @click="getimg(1)">-->
          <div @click="changechartType(1)">
            <el-icon>
              <d-caret />
            </el-icon>
            切换
          </div>
        </div>
      </div>
      <div id="bcresource">
        <div class="title">来源统计</div>
        <div class="yz" v-show="resourceShow==1">
          <div class="yz-title">
            <div>广播总数</div>
            <div class="yz-title-bg">{{ zs }}</div>
          </div>
          <div class="yzchart" id="yz"></div>
        </div >
        <div id="resource" style="width: 586px;height: 392px;" class="chart flex" v-show="resourceShow==2"></div>

        <div id="resourcepie" style="width: 586px;height: 392px;" class="chart flex" v-show="resourceShow==3"></div>
        <div class="download">
          <div class="year">{{ year }}年</div>
<!--          <img src="../../assets/download.png" @click="getimg(2)">-->
          <div @click="changechartType(2)">
            <el-icon>
              <d-caret />
            </el-icon>
            切换
          </div>
        </div>
      </div>
    </div>
<!--    administrator-->
    <div class="chart-bom">
      <div class="chart-bom-flex">
        <div class="l-top">
          <div class="title">设备统计</div>
          <div class="num-t">
            <div class="num-t-l">
              <div class="num-t-l-in">
                <div>{{ bycount.onlineLv }}</div>
                <div>在线率</div>
              </div>
            </div>

            <div class="num-t-r">
              <div class="num-t-r-top">
                <div class="num-t-r-top-text">终端总数</div>
                <div class="num-t-r-top-num"
                     v-for="(item,index) in (Array(4).join(0) + bycount.total).slice(-4)"
                     :key="index">{{ item }}
                </div>
              </div>

              <div class="num-t-r-bom">
                <div class="num-t-r-bom-list">
                  <div class="num-t-r-bom-list-l">
                    在线终端
                  </div>
                  <div class="num-t-r-bom-list-line"></div>
                  <div class="num-t-r-bom-list-r">
                    {{ bycount.onlineSum }}
                  </div>
                </div>
                <div class="num-t-r-bom-list">
                  <div class="num-t-r-bom-list-l">
                    离线终端
                  </div>
                  <div class="num-t-r-bom-list-line"></div>
                  <div class="num-t-r-bom-list-r">
                    {{ bycount.offlineSum }}
                  </div>
                </div>
                <div class="num-t-r-bom-list">
                  <div class="num-t-r-bom-list-l">
                    无回传终端
                  </div>
                  <div class="num-t-r-bom-list-line"></div>
                  <div class="num-t-r-bom-list-r">
                    {{ bycount.unRebackSum }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="r-top">
          <div class="title">在线率统计</div>
          <div class="databox">
            <div class="choose">
              <div v-for="(item,index) in chooseList" :key="index" :class="idx == index && 'active'"
                   @click="change(item,index)">{{ item }}
              </div>
            </div>
            <vue-seamless-scroll :class-option="optionLeft" :data="resbList" class="seamless-warp">
              <div class="tub">
                <div v-for="(item,index) in resbList" :key="index">
                  <div class="img">
                    <div class="lv">
                      <div>{{ item.online }}</div>
                      <div>在线率</div>
                    </div>
                  </div>
                  <div class="tubdata">
                    <div><span>设备总数：</span><span>{{ item.total }}</span></div>
                    <div v-if="!idx"><span>设备类型：</span><span>{{ item.name }}</span></div>
                    <div v-else><span>设备级数：</span><span>{{ item.name }}</span></div>
                    <div><span>在线率：</span><span>{{ item.online }}</span></div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="tablebox">
        <div class="title">实时广播情况</div>
        <div class="table">
          <table border="1" width="100%" bordercolor="#10514C">
            <thead>
            <tr>
              <th>应急广播消息编号</th>
              <th>开播时间</th>
              <th>覆盖区域</th>
              <th>消息级别</th>
              <th>覆盖率</th>
              <th>发布时效</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(item,index) in bList" :key="index">
              <td style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{ item.ebmId }}</td>
              <td>{{ item.startTimeFact }}</td>
              <td>{{ item.targetNames }}</td>
              <td>
                {{ item.eventLevel == 1 ? "特别重大" : item.eventLevel == 2 ? "重大" : item.eventLevel == 3 ? "较大" : "一般" }}
              </td>
              <td>{{ item.deviceCoverageRate }}</td>
              <td>{{ item.effectTime }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bcastCount, dataCount, dataNameCount, dataNumber, bcastList, datdBycount } from "../../api/screen/effect";
import * as echarts from "echarts";
import { computed, onMounted, onBeforeUnmount, reactive, ref, toRefs } from "vue";
import html2canvas from "html2canvas";

export default {
  setup() {
    let idx = ref(0);
    let zs = ref(0);
    let nums = ref(0);
    let state = reactive({
      year: new Date().getFullYear(),
      chooseList: ["设备类型", "行政级别"],
      bcasCount: [],
      timer: null,
      bycount: {},
      sbList: [],
      bList: [],
      resbList: [],
      chartType: "bar",
      gbData: [],
      bcastData: [],
      levelShow: 1,
      resourceShow: 1
    });
    const optionLeft = computed(() => {
      return {
        openWatch: true,
        direction: 2,
        limitMoveNum: 2 // 开始无缝滚动的数据量 this.dataList.length
      };
    });

    function yzChart(data) {
      var chartDom = document.getElementById("yz");
      var myChart = echarts.init(chartDom);
      var xData = data.map(item => item.name);
      var line = data.map(item => item.num);
      let option = {
        grid: {
          top: "5%"
        },
        toolbox:{
          show: true,
          feature:{
            saveAsImage: {
              show:true,
              excludeComponents :['toolbox'],
              pixelRatio: 2
            }
          }
        },
        xAxis: [{
          data: xData,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#aaaaaa",
              fontSize: 12
            },
            margin: 30 //刻度标签与轴线之间的距离。
          },
          axisLine: {
            show: false //不显示x轴
          },
          axisTick: {
            show: false //不显示刻度
          },
          boundaryGap: true,
          splitLine: {
            show: false,
            width: 0.08,
            lineStyle: {
              type: "solid",
              color: "#03202E"
            }
          }
        }],
        yAxis: [{
          show: false,
          splitLine: {
            show: false,
            lineStyle: {
              color: "#eee",
              type: "solid"
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              color: "#888"
            }
          }
        }],
        series: [
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [60, 22],//调整截面形状
            symbolOffset: [0, 10],
            z: 12,
            label: {
              show: true,
              position: "top"
            },
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorArr = ["#C1232B", "#B5C334",
                    "#FCCE10", "#E87C25", "#27727B",
                    "#FE8463", "#9BCA63"];
                  return colorArr[params.dataIndex];
                }
              }
            },
            data: line
          },

          //柱体
          {
            name: "",
            type: "bar",
            barWidth: 60,
            barGap: "0%",
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorList = ["#C1232B", "#B5C334", "#FCCE10", "#E87C25", "#27727B", "#FE8463", "#9BCA63", "#FAD860"];
                  return colorList[params.dataIndex];
                },
                opacity: .7
              }
            },
            data: line
          },
          //柱顶圆片
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [60, 22],//调整截面形状
            symbolOffset: [0, -10],
            z: 12,
            symbolPosition: "end",
            "itemStyle": {
              "normal": {
                color: function(params) {
                  var colorArr = ["#C1232B", "#B5C334", "#FCCE10", "#E87C25", "#27727B", "#FE8463", "#9BCA63"];
                  return colorArr[params.dataIndex];
                }
              }
            },
            data: line
          }
        ]
      };
      option && myChart.setOption(option);
    };

    function gbChart(data) {
      var chartDom = document.getElementById("gb");
      var myChart = echarts.init(chartDom);
      let option = {
        toolbox:{
          show: true,
          feature:{
            saveAsImage: {
              show:true,
              excludeComponents :['toolbox'],
              pixelRatio: 2
            }
          }
        },
        grid: {
          top: "5%"
        },
        xAxis: {
          type: "category",
          data: data.map(item => item.name),
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#00E7C2"
            },
            symbol: ["none", "arrow"],
            symbolSize: [8, 8]
          }
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "#163433"
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#00E7C2"
            },
            symbol: ["none", "arrow"],
            symbolSize: [8, 8]
          }
        },
        series: [
          {
            data: data.map(item => item.num),
            type: state.chartType,
            showBackground: true,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E7C2" },
                { offset: 1, color: "#0C3834" }
              ])
            }
          }
        ]
      };

      option && myChart.setOption(option);
    };

    function change(_item, _index) {
      idx.value = _index;
      state.resbList = state.sbList.filter(item => item.name == _item)[0].value;
    }

    function changechartType(type) {
      if (type == 0) {
        state.chartType = state.chartType == "bar" ? "line" : "bar";
        gbChart(state.gbData);
      } else if (type == 1) {
        state.levelShow += 1;
        if(state.levelShow > 3) state.levelShow = 1;
        levelCharts();
        levelpie();
      }else if (type == 2) {
        state.resourceShow += 1;
        if(state.resourceShow > 3) state.resourceShow = 1;
        resourcecharts();
        resourcepie()
      }

    }

    function levelpie(data = state.bcasCount){
      var chartDom = document.getElementById("levelpie");
      var myChart = echarts.init(chartDom);
      let option = {
        toolbox:{
          show: true,
          feature:{
            saveAsImage: {
              show:true,
              excludeComponents :['toolbox'],
              pixelRatio: 2
            }
          }
        },
        grid: {
          top: "5%"
        },
        legend: {
          show: false,
          icon: 'circle',
          right: '3%',
          left: 'center',
          bottom: '10%',
          itemWidth: 10,
          itemStyle: {
            borderColor: 'none',
          },
          textStyle: {
            rich: {
              name: {
                color: '#606266',
                fontSize: 14,
              },
              percentage: {
                color: '#606266',
                fontSize: 16,
                padding: [0, 0, 0, 30],
                align: 'right',
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
        },
        series : [
          {
            color: ['#C8321E', '#E06C02', '#E5D414', '#0AC1D6', '#FFFFFF'],
            type: 'pie',
            radius: '80%',
            data: data.map(item=>{
              return{
                value:item.num,
                name: item.name
              }
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 60,
            },
            label: {
              normal:{
                formatter: params => {
                  return params.value + '\n' + params.name
                },
              },
            },
            itemStyle: {
              borderColor: '#fff',
            },
          }
        ]
      }
      option && myChart.setOption(option);
    }

    function levelCharts(data = state.bcasCount) {
      var chartDom = document.getElementById("levelcharts");
      var myChart = echarts.init(chartDom);
      let option = {
        toolbox:{
          show: true,
          feature:{
            saveAsImage: {
              show:true,
              excludeComponents :['toolbox'],
              pixelRatio: 2
            }
          }
        },
        grid: {
          top: "5%"
        },
        xAxis: {
          type: "category",
          data: data.map(item => item.name),
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#00E7C2"
            },
            symbol: ["none", "arrow"],
            symbolSize: [8, 8]
          }
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "#163433"
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#00E7C2"
            },
          }
        },
        series: [
          {
            data: data.map(item => item.num),
            type: "line",
            showBackground: true,
            itemStyle: {
              borderWidth: 3,
              borderColor: "#00E7C2",
              color: (params) => {
                let arr=new Map([
                  [0, "#fff"],
                  [1, "#C8321E"],
                  [2, "#E06C02"],
                  [3, "#0AC1D6"],
                ]);
                return arr.get(params.dataIndex)
              }
            },
            lineStyle:{
              color:"yellow"
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }

    function resourcepie(data = state.bcastData){
      var chartDom = document.getElementById("resourcepie");
      var myChart = echarts.init(chartDom);
      let option = {
        toolbox:{
          show: true,
          feature:{
            saveAsImage: {
              show:true,
              excludeComponents :['toolbox'],
              pixelRatio: 2
            }
          }
        },
        grid: {
          top: "5%"
        },
        legend: {
          show: false,
          icon: 'circle',
          right: '3%',
          left: 'center',
          bottom: '10%',
          itemWidth: 10,
          itemStyle: {
            borderColor: 'none',
          },
          textStyle: {
            rich: {
              name: {
                color: '#606266',
                fontSize: 14,
              },
              percentage: {
                color: '#606266',
                fontSize: 16,
                padding: [0, 0, 0, 30],
                align: 'right',
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
        },
        series : [
          {
            type: 'pie',
            radius: '80%',
            // left: 'center',
            data: data.map(item=>{
              return{
                value:item.num,
                name: item.name
              }
            }),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            labelLine: {
              show: true,
              length: 10,
              length2: 60,
            },
            label: {
              normal:{
                formatter: params => {
                  return params.value + '\n' + params.name
                },
              },
            },
            itemStyle: {
              borderColor: '#fff',
            },
          }
        ]
      }
      option && myChart.setOption(option);
    }


    function resourcecharts(data = state.bcastData) {
      var chartDom = document.getElementById("resource");
      var myChart = echarts.init(chartDom);
      let option = {
        toolbox:{
          show: true,
          feature:{
            saveAsImage: {
              show:true,
              excludeComponents :['toolbox'],
              pixelRatio: 2
            }
          }
        },
        grid: {
          top: "5%"
        },
        xAxis: {
          type: "category",
          data: data.map(item => item.name),
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#00E7C2"
            },
            symbol: ["none", "arrow"],
            symbolSize: [8, 8]
          }
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              type: "solid",
              color: "#163433"
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#00E7C2"
            },
          }
        },
        series: [
          {
            data: data.map(item => item.num),
            type: "line",
            showBackground: true,
            itemStyle: {
              normal: {
                color: function(params) {
                  var colorArr = ["#C1232B", "#B5C334",
                    "#FCCE10", "#E87C25", "#27727B",
                    "#FE8463", "#9BCA63"];
                  return colorArr[params.dataIndex];
                }
              }
            },
            lineStyle:{
              color:"#00E7C2"
            }
          }
        ]
      };

      option && myChart.setOption(option);
    }

    function getimg(type) {
      let dom;
      if (type == 0) dom = document.getElementById("bcType");
      if (type == 1) dom = document.getElementById("levelBox");
      if (type == 2) dom = document.getElementById("bcresource");
      html2canvas(dom, {
        dpi: 80,
        useCORS: true,
        scale: 4,
        async: true,
        width: dom.offsetWidth,
        height: dom.offsetHeight
        // x: that.getActuralPosition(dom).left + parseInt(that.getScrollWidth()/2),
        // y: that.getActuralPosition(dom).top,
      }).then((canvas) => {
        let ADOM = document.createElement("a");
        ADOM.href = canvas.toDataURL("image/jpeg");
        let name = new Date().getTime() + ".jpg";
        ADOM.download = name;
        ADOM.click();
      }).catch(() => {

      });
    }

    function allHttp(){
      bcastCount({ year: state.year }).then(res => {
        state.bcasCount = res.data.data;
        nums.value = state.bcasCount.map(item => item.num).reduce((e, v) => e + v);
      });

      dataNameCount({ year: state.year }).then(res => {
        state.gbData = res.data.data;
        gbChart(res.data.data);
      });

      dataCount({ year: state.year }).then(res => {
        zs.value = res.data.data.total;
        yzChart(res.data.data.list);
        state.bcastData=res.data.data.list
      });

      datdBycount({ year: state.year }).then(res => {
        state.bycount = res.data.data;
      });

      dataNumber().then(res => {
        state.sbList = res.data.data;
        state.resbList = state.sbList.filter(item => item.name == "设备类型")[0].value;
      });

      bcastList({ page: 1, limit: 10 }).then(res => {
        state.bList = res.data.data.list;
      });
    }

    onBeforeUnmount(()=>{
      clearInterval(state.timer);
      state.timer = null;
    })

    onMounted(() => {
      allHttp()

      state.timer = setInterval(()=>{
        allHttp()
      }, 1000 * 30)

    });
    return {
      zs,
      idx,
      nums,
      optionLeft,
      changechartType,
      getimg,
      change,
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;

  .chart-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 450px;
    grid-column-gap: 20px;

    .yz {
      margin: 15px 40px 0 40px;
      width: calc(100% - 80px);
      height: calc(100% - 28px - 30px);

      .yzchart {
        margin-top: 20px;
        width: 100%;
        height: 300px;
      }

      .yz-title {
        width: 200px;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 400;
        color: #95D7C8;

        .yz-title-bg {
          width: 100%;
          height: 36px;
          background: url("../../assets/yz-bg.png") no-repeat center;
          background-size: 100% 100%;
          font-size: 20px;
          font-weight: 500;
          color: #00FFC6;
          line-height: 36px;
        }
      }
    }

    > div {
      background: #061715;
      position: relative;

      .title {
        margin: 0 auto;
        width: 216px;
        height: 28px;
        background: url("../../assets/bigtitle.png") no-repeat center;
        background-size: 100% 100%;
        font-size: 19px;
        font-weight: 300;
        color: #FFFFFF;
      }

      .chart {
        margin: 20px 20px 0 20px;
        width: calc(100% - 40px);
        height: calc(100% - 58px);

        &.flex {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .chart-line {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 30px;
            margin-bottom: 25px;

            .chart-line-l {
              width: 130px;
              font-size: 22px;
              font-weight: 400;
              color: #95D7C8;
              text-align: right;
              margin-right: 20px;
              position: relative;

              &::before {
                content: "";
                display: inline-block;
                width: 5px;
                height: 30px;
                background: red;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
              }

              &.one {
                &::before {
                  background: #C8321E;
                }
              }

              &.two {
                &::before {
                  background: #E06C02;
                }
              }

              &.three {
                &::before {
                  background: #E5D414;
                }
              }

              &.four {
                &::before {
                  background: #0AC1D6;
                }
              }

              &.five {
                &::before {
                  background: #FFFFFF;
                }
              }
            }

            .chart-line-r {
              width: 400px;
              height: 30px;
              background: #062B2A;
              border-radius: 12px;
              position: relative;

              .chart-line-r-in {
                display: inline-block;
                height: 30px;
                border-radius: 12px;
                position: absolute;
                left: 0;

                &.one {
                  background: #C8321E;
                }

                &.two {
                  background: #E06C02;
                }

                &.three {
                  background: #E5D414;
                }

                &.four {
                  background: #0AC1D6;
                }

                &.five {
                  background: #FFFFFF;
                }
              }

              .chart-line-r-num {
                position: absolute;
                z-index: 9;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 18px;
                font-weight: 400;
                color: #95D7C8;
                line-height: 30px;
              }
            }
          }
        }
      }

      .download {
        width: 150px;
        position: absolute;
        right: 20px;
        top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #00E5C0;
        cursor: pointer;

        .year {
          width: 70px;
          //background: url("../../assets/year.png") no-repeat left top;
          background-size: 100% 100%;
        }
      }
    }
  }

  .tablebox {
    padding: 0 20px;
    width: calc(100% - 40px) !important;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .title {
      margin: 0 auto;
      width: 270px;
      height: 40px;
      background: url("../../assets/bigtitle.png") no-repeat center;
      background-size: 100% 100%;
      font-size: 24px;
      font-weight: 300;
      color: #FFFFFF;
    }

    .table {
      width: 100%;
      margin-top: 30px;
      height: calc(100% - 70px);

      thead tr th {
        height: 50px;
        background: #10433a;
        font-size: 19px;
        font-weight: 300;
        color: #00FFC6;
        line-height: 50px
      }

      tbody tr td {
        height: 70px;
        font-size: 16px;
        font-weight: 300;
        color: #82BDB0;
      }
    }
  }

  .chart-bom {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 750px calc(100% - 750px);
    grid-template-rows: 600px;
    grid-column-gap: 20px;

    > div {
      background: #061715;
      width: 100%;

      &.chart-bom-flex {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-row-gap: 20px;

        > div .title {
          margin: 0 auto;
          width: 216px;
          height: 28px;
          background: url("../../assets/bigtitle.png") no-repeat center;
          background-size: 100% 100%;
          font-size: 19px;
          font-weight: 300;
          color: #FFFFFF;
        }

        .num-t {
          margin: 10px;
          width: calc(100% - 20px);
          height: calc(100% - 20px - 28px);
          display: flex;
          align-items: center;

          .num-t-l {
            background: url("../../assets/circlebg.png") no-repeat center;
            background-size: 100% 100%;
            width: 240px;
            height: 240px;
            margin-right: 20px;
            position: relative;

            .num-t-l-in {
              display: flex;
              flex-direction: column;
              font-weight: 400;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              align-items: center;
              justify-items: center;

              div:nth-child(1) {
                font-size: 36px;
                color: #FFFFFF;
              }

              div:nth-child(2) {
                font-size: 22px;
                color: #95D7C8;
              }
            }
          }

          .num-t-r {
            margin-left: 30px;
            width: calc(100% - 360px);
            height: 240px;

            .num-t-r-bom {
              margin-top: 13px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .num-t-r-bom-list {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .num-t-r-bom-list-l {
                  font-size: 26px;
                  font-weight: 400;
                  color: #95D7C8;
                  display: flex;
                  align-items: center;
                  margin-bottom: 20px;

                  &:before {
                    content: "";
                    display: block;
                    width: 25px;
                    height: 25px;
                    background: #00FFC6;
                    border-radius: 50%;
                    margin-right: 20px;
                    box-shadow: 0px 0px 0px 5px #055a49;
                  }
                }

                .num-t-r-bom-list-line {
                  width: 120px;
                  height: 1px;
                  border: 1px #163B33 dashed;
                }

                .num-t-r-bom-list-r {
                  font-size: 24px;
                  font-weight: 400;
                  color: #00FFC6;
                }
              }
            }

            .num-t-r-top {
              width: 100%;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: space-around;

              .num-t-r-top-text {
                height: 38px;
                background: #0E2722;
                border: 1px solid #327566;
                width: 106px;
                line-height: 38px;
                font-size: 18px;
                font-weight: 300;
                color: #00FFC6;
              }

              .num-t-r-top-num {
                width: 36px;
                height: 38px;
                background: #0E2722;
                border: 1px solid #327566;
                line-height: 38px;
                font-size: 30px;
                font-weight: 400;
                color: #00FFC6;
              }
            }
          }
        }

        .databox {
          margin: 20px 20px 0 20px;
          width: calc(100% - 40px);
          height: calc(100% - 20px - 32px);

          .seamless-warp {
            width: 710px;
            overflow: hidden;

            .tub {
              width: 100%;
              height: calc(100% - 40px - 30px);
              margin-top: 30px;
              display: flex;

              > div {
                width: 345px;
                height: 180px;
                display: flex;
                align-items: center;
                justify-content: center;

                .img {
                  width: 160px;
                  height: 160px;
                  background: url("../../assets/kkk.png") no-repeat center;
                  background-size: 100% 100%;
                  position: relative;

                  .lv {
                    display: flex;
                    flex-direction: column;
                    font-weight: 400;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    align-items: center;
                    justify-items: center;

                    div:nth-child(1) {
                      font-size: 26px;
                      color: #FFFFFF;
                    }

                    div:nth-child(2) {
                      font-size: 20px;
                      color: #95D7C8;
                    }
                  }
                }

                .tubdata {
                  height: 180px;
                  width: 165px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  font-size: 18px;
                  font-weight: 300;
                  color: #95D7C8;

                  > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;

                    span {
                      color: #00FFC6;

                      &:nth-child(1) {
                        flex-basis: 90px;
                        text-align: left;
                      }

                      &:nth-child(2) {
                        flex-basis: calc(100% - 90px);
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }
                    }
                  }
                }
              }
            }
          }

          .choose {
            width: 200px;
            height: 40px;
            display: flex;
            align-items: center;
            font-size: 22px;
            font-weight: 300;
            color: #00FFC6;
            cursor: pointer;

            > div {
              flex-basis: 50%;
              background: #143a31;

              &.active {
                background: #342f07;
                color: #E8BD3B;
              }
            }
          }
        }
      }
    }
  }
}
</style>
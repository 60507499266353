import axios from "@/api/axios"; // 引用axios

export const gettimeInterval = (params={}) => {
  return axios({
    url: "/api/big/screen/getNewSensorBasic",
    method: "post",
    params: params,
  });
};
export const getvideoData = (cName='') => {
  return axios({
    url: "/api/big/screen/getUserCameraList",
    method: "post",
    params: {

    },
  });
};

